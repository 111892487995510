import SumsubWebSdk from "@sumsub/websdk-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AvererContainer } from "./AvererContainer";
import "./LandingPage.css";
import { NotFoundScreen } from "./NotFoundScreen";
import { UUID_REGEX } from "./constants";
import loaderImg from "./images/loader.svg";

const WHITELISTED_DOMAIN = `${process.env.REACT_APP_WHITELISTED_DOMAIN}`;

export const Sumsub = () => {
  const [isValidEntityId, setValidEntityId] = React.useState(true);
  const [accessToken, setAccessToken] = React.useState("");
  const { entityId } = useParams();
  const [loader, setLoader] = React.useState(true);

  const messageHandler = React.useCallback(
    (event) => {
      if (event.origin !== WHITELISTED_DOMAIN) return;
      if (entityId.match(UUID_REGEX)) {
        setValidEntityId(true);
        if (!accessToken) {
          setAccessToken(event.data.token);
          setLoader(false);
        }
      } else {
        setValidEntityId(false);
      }
    },
    [accessToken, entityId]
  );

  React.useEffect(() => {
    window.addEventListener("message", messageHandler, false);
    return () => {
      window.removeEventListener("message", messageHandler, false);
    };
  }, [messageHandler]);

  const accessTokenExpirationHandler = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        resolve(accessToken);
      } catch (error) {
        reject(error);
      }
    });
  }, [accessToken]);

  if (!WHITELISTED_DOMAIN) return <NotFoundScreen />;

  return isValidEntityId ? (
    <AvererContainer>
      {loader && (
        <div className="animated-loader">
          <img src={loaderImg} width="100" height="100" alt="loader" />
        </div>
      )}
      {accessToken && !loader && (
        <SumsubWebSdk
          accessToken={accessToken}
          expirationHandler={accessTokenExpirationHandler}
        />
      )}
    </AvererContainer>
  ) : (
    <NotFoundScreen />
  );
};
